<template>
  <page-layout>
    <div class="d-flex justify-content-center">
      <div class="card shadow">
        <div class="card-body">
          <div v-if="tasks.length" class="d-flex align-items-center">
            <h5 class="px-4 m-0">Tasks</h5>
            <el-pagination
              layout="prev, pager, next, jumper"
              :page-count="tasks.length"
              :current-page.sync="currentPage"
              background
            />
          </div>
          <h5 v-else>No Tasks</h5>
        </div>
      </div>
    </div>
    <div v-if="currentTask" :key="currentTask._id" class="row mt-7">
      <agreement-form
        ref="agreementForm"
        :initial-data="currentTask.data"
        :has-files="!isEmpty(currentTask.files)"
        @formChange="updateTaskWithFormData"
        @formSubmit="submit"
      >
        <template #context>
          <div>
            <div class="float-right">
              {{ currentTask.createdAt | formatDate('DDD') }}
              <a v-if="currentTask.communicationService === 'zendesk'" :href="zendeskLink" target="_blank" class="mx-4"
                >open in zendesk</a
              >
              <a v-else :href="frontLink" target="_blank" class="mx-4">open in front</a>
            </div>
            <div class="d-flex mb-4">
              <el-dropdown trigger="click" @command="handleCommand">
                <el-button size="mini" icon="el-icon-more" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="addFile"> Add new file </el-dropdown-item>
                  <el-dropdown-item command="moveCurrentFile"> Move current file to new task </el-dropdown-item>
                  <el-dropdown-item command="removeCurrentFile"> Remove current file </el-dropdown-item>
                  <el-dropdown-item v-if="fileUrl && isPdfFile(previewedFile) && !ocrDocument" command="runOcr"
                    >Run OCR</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-radio-group v-model="fileIndex" size="mini" class="mx-4">
                <el-radio-button v-for="(file, i) in currentTask.files" :key="i" :label="i">
                  {{ file.filename }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <div v-if="fileUrl">
              <file-viewer
                v-if="!isPdfFile(previewedFile)"
                :url="fileUrl"
                style="min-height: calc(100vh - (251px + 4rem))"
              />
              <template v-else>
                <ocr-document
                  v-if="ocrDocument"
                  :doc-src="fileUrl"
                  :ocr-doc="ocrDocument"
                  @blocksSelection="onBlockSelection"
                />
                <div v-else>
                  <pdf-viewer :src="fileUrl" />
                </div>
              </template>
            </div>
          </div>
        </template>
      </agreement-form>
    </div>
  </page-layout>
</template>

<script type="text/javascript">
import { Slingshot } from 'meteor/edgee:slingshot';
import xml2js from 'xml2js';
import { Document, Block } from '@clarityo/ocr-reader';
import { FileViewer } from '@clarityo/ui-components';
import { isEmpty } from 'ramda';
import config from '@/config';

import { Tasks } from '@/imports/api/collections';
import { getCurrentRestaurant } from '@/modules/core/compositions/meteor-restaurants';
import { processImage } from '@/modules/templates/compositions/documents-operations';
import { PdfViewer } from '@/modules/core/components/PdfViewer';
import { OcrDocument } from '@/modules/document/components';
import AgreementForm from '@/modules/purchase-management/components/agreements/AgreementForm';
import { AGREEMENT_FILE_UPLOAD_QUERY, CREATE_AGREEMENT_MUTATION } from '@/modules/purchase-management';
import { useTenancy } from '@/modules/auth';

import {
  closeTask,
  createTaskFromTaskFile,
  updateTaskData,
  addFile,
  removeFile,
} from './compositions/tasks-operations';
import { getSignedUrl } from './compositions/slingshot-operations';

const getOCRDocument = (xmlData) => {
  const stringData = new TextDecoder('utf-8').decode(xmlData);
  return new Promise((resolve, reject) => {
    const parser = new xml2js.Parser({ attrValueProcessors: [xml2js.processors.parseNumbers] });
    parser.parseString(stringData, (err, result) => {
      if (err) reject(err);
      else resolve(Document.createFromXml(result.document));
    });
  });
};

export default {
  components: {
    AgreementForm,
    OcrDocument,
    PdfViewer,
    FileViewer,
  },
  setup() {
    const { currentTenant } = useTenancy();
    return {
      currentTenant,
    };
  },
  data() {
    return {
      currentTaskIndex: 0,
      purchasedItems: [],
      model: {},
      fileIndex: 0,
      fileUrl: '',
      ocrDocument: null,
      isEmpty,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.currentTaskIndex + 1;
      },
      set(value) {
        this.currentTaskIndex = value - 1;
      },
    },
    currentTask() {
      return this.tasks && this.tasks[this.currentTaskIndex];
    },
    frontLink() {
      return `https://app.frontapp.com/open/${this.currentTask.communicationReference}`;
    },
    zendeskLink() {
      return `${config.VUE_APP_ZENDESK_URL}/agent/tickets/${this.currentTask.communicationReference}`;
    },
    previewedFile() {
      if (!this.currentTask) return;
      return this.currentTask.files[this.fileIndex];
    },
  },
  watch: {
    'currentTask._id'() {
      this.fileIndex = 0;
      this.model = {};
    },
    async 'previewedFile.key'(key) {
      this.ocrDocument = null;
      this.fileUrl = null;
      if (!key) return;
      const url = await getSignedUrl({ key });
      if (!this.previewedFile || key !== this.previewedFile.key) return;
      this.fileUrl = url;
    },
  },
  methods: {
    async uploadFile(taskFiles) {
      const {
        data: { agreementFileUpload },
      } = await this.$apollo.query({
        query: AGREEMENT_FILE_UPLOAD_QUERY,
        variables: {
          filenames: taskFiles.map(({ filename }) => filename),
          businessId: this.currentTenant.id,
        },
      });
      const filePaths = await Promise.all(
        agreementFileUpload.map(async ({ url, filePath, fields }, index) => {
          const fileUrl = await getSignedUrl({ key: taskFiles[index].key });
          const fileResponse = await fetch(fileUrl);
          const file = await fileResponse.blob();

          const parsedFields = JSON.parse(fields);
          const formData = new FormData();
          Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));

          formData.append('file', file);
          const response = await fetch(url, { method: 'POST', body: formData });
          if (!response.ok) throw new Error();
          return filePath;
        })
      );
      return filePaths;
    },
    updateTaskWithFormData(formData) {
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(
        (taskId) => {
          delete this.timeoutId;
          if (taskId !== this.currentTask._id) return;
          updateTaskData({
            taskId: taskId,
            data: formData,
          });
        },
        500,
        this.currentTask._id
      );
    },
    isPdfFile(file) {
      return file.filename.endsWith('.pdf');
    },
    async submit(params) {
      const loading = this.$loading();
      try {
        const filePaths = await this.uploadFile(this.currentTask.files);
        params.filePaths = filePaths;
        params.businessId = this.currentTenant.id;
        await this.$apollo.mutate({
          mutation: CREATE_AGREEMENT_MUTATION,
          variables: { agreementCreateInput: params },
        });
        await closeTask({ taskId: this.currentTask._id });
        this.$message.success('Agreement created successfully');
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      } finally {
        loading.close();
      }
    },
    async handleCommand(cmd) {
      if (cmd === 'addFile') {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = async (e) => {
          const file = e.target.files[0];
          if (!file) return;
          const fileUpload = new Slingshot.Upload('documentsDirective');
          const loading = this.$loading();
          try {
            await new Promise((resolve, reject) => {
              fileUpload.send(file, (err) => (err ? reject(err) : resolve()));
            });
            await addFile({
              taskId: this.currentTask._id,
              file: {
                key: fileUpload.param('key'),
                filename: file.name,
              },
            });
            this.$message.success('Added file');
          } catch (err) {
            this.$message.error(`${err.error} - ${err.reason}`);
          } finally {
            loading.close();
          }
        };
        input.click();
      }
      if (cmd === 'moveCurrentFile') {
        const loading = this.$loading();
        try {
          await createTaskFromTaskFile({
            taskId: this.currentTask._id,
            fileIndex: this.fileIndex,
          });
          this.$message.success('Created new task');
        } catch (err) {
          this.$message.error(`${err.error} - ${err.reason}`);
        } finally {
          loading.close();
        }
      }
      if (cmd === 'removeCurrentFile') {
        const loading = this.$loading();
        try {
          await removeFile({
            taskId: this.currentTask._id,
            fileIndex: this.fileIndex,
          });
          this.$message.success('Removed file');
        } catch (err) {
          this.$message.error(`${err.error} - ${err.reason}`);
        } finally {
          loading.close();
        }
      }
      if (cmd === 'runOcr') {
        await this.runOCR();
      }
    },
    async runOCR() {
      const loading = this.$loading();
      try {
        const response = await fetch(this.fileUrl);
        const buffer = await response.arrayBuffer();
        const ocrData = await processImage({ fileData: new Uint8Array(buffer) });
        this.ocrDocument = await getOCRDocument(ocrData);
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      } finally {
        loading.close();
      }
    },
    onBlockSelection(blocks) {
      const sortedBlocks = [...blocks].sort(Block.compareVertically);
      const data = sortedBlocks.map((block) => block.text);

      this.$refs.agreementForm.pasteToLastFocusedField(data);
    },
  },
  meteor: {
    $subscribe: {
      'priceIndexes.all': [],
      'tasks.all'() {
        return [this.restaurantId];
      },
    },
    restaurantId: getCurrentRestaurant,
    tasks() {
      return Tasks.find({ type: 'recordContractsAndTerms' });
    },
  },
};
</script>

<style scoped lang="scss"></style>
