<template>
  <div class="container">
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">Purchases</h6>
        <h2 class="dashhead-title">Suppliers</h2>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <el-input v-model="searchTerm" prefix-icon="el-icon-search" placeholder="Search (by name)" clearable></el-input>
      </div>
      <!-- <div class="col">
        <el-button class="float-right" @click="dialogVisible = true">Add</el-button>
      </div> -->
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Items</th>
          <th>Unclassified</th>
          <th>Unreviewed</th>
          <th>Pending</th>
          <th>Last Purchase</th>
          <th style="width: 4rem"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!$subReady['suppliers.all']">
          <td v-loading="true" colspan="7" style="height: 6rem"></td>
        </tr>
        <template v-else-if="suppliers.length">
          <tr v-for="supplier in suppliers" :key="supplier._id" @click="routeToItems(supplier._id)">
            <td>{{ supplier.name }}</td>
            <template v-if="getSupplierItemsCount(supplier._id)">
              <td>{{ getSupplierItemsCount(supplier._id).count }}</td>
              <td class="text-danger">
                <template v-if="getSupplierItemsCount(supplier._id).unknownItemsCount">
                  {{ getSupplierItemsCount(supplier._id).unknownItemsCount }}
                </template>
              </td>
              <td>{{ getSupplierUnreviewedCount(supplier._id) }}</td>
              <td>
                <el-tooltip
                  v-if="getSupplierItemsCount(supplier._id).pendingCount"
                  placement="left"
                  :content="getPendingReasonCount(supplier._id)"
                >
                  <span>{{ getSupplierItemsCount(supplier._id).pendingCount }}</span>
                </el-tooltip>
              </td>
            </template>
            <template v-else>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </template>
            <td>
              <template v-if="getSupplierLastPurchase(supplier._id)">
                {{ getSupplierLastPurchase(supplier._id).date | moFormatDate('L') }}
              </template>
            </td>
            <td>
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                class="p-0"
                @click.stop="editSupplier(supplier._id)"
              />
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="7">No Suppliers</td>
        </tr>
      </tbody>
    </table>
    <el-pagination
      small
      background
      :total="totalSuppliers"
      :page-size="rowsPerPage"
      :current-page.sync="page"
      layout="prev, slot, total, next"
      class="text-center"
    >
      <span class="el-pagination__total">Showing {{ firstRowIndex }} - {{ lastRowIndex }}, </span>
    </el-pagination>

    <supplier-form
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :supplier-id="editedSupplierId"
      :defaults="editedSupplier"
      :supplier-company-id="editedSupplier?.number"
      @close="closeSupplierForm"
    />
  </div>
</template>

<script type="text/javascript">
import escapeStringRegexp from 'escape-string-regexp';
import { Suppliers } from '@/imports/api/collections';
import SupplierForm from './components/SupplierForm';
import { getSuppliersSummaryData } from './compositions/suppliers-oprations';

export default {
  components: { SupplierForm },
  data() {
    return {
      page: 1,
      rowsPerPage: 10,
      searchTerm: '',
      summaryData: { itemsCount: {}, lastPurchase: {} },
      dialogVisible: false,
      editedSupplierId: null,
    };
  },
  computed: {
    filteredSuppliers() {
      return this.allSuppliers.filter((supplier) => this.searchRegexp.test(supplier.name));
    },
    suppliers() {
      return this.filteredSuppliers.slice((this.page - 1) * this.rowsPerPage, this.page * this.rowsPerPage);
    },
    totalSuppliers() {
      return this.filteredSuppliers.length;
    },
    firstRowIndex() {
      return this.totalSuppliers && (this.page - 1) * this.rowsPerPage + 1;
    },
    lastRowIndex() {
      return Math.min(this.page * this.rowsPerPage, this.totalSuppliers);
    },
    searchRegexp() {
      const nonContinuity = this.searchTerm.split('').map(escapeStringRegexp).join('.{0,2}');
      return new RegExp(nonContinuity, 'i');
    },
    editedSupplier() {
      const supplier = this.suppliers.find((s) => s._id === this.editedSupplierId);
      if (supplier) {
        const supplierCopy = JSON.parse(JSON.stringify(supplier));
        delete supplierCopy._id;
        return supplierCopy;
      }
      return null;
    },
  },
  created() {
    getSuppliersSummaryData()
      .then((result) => {
        this.summaryData = result;
      })
      .catch((err) => {
        this.$notify.error({ title: err.error, message: err.reason });
      });
  },
  methods: {
    getSupplierItemsCount(supplierId) {
      return this.summaryData.itemsCount[supplierId];
    },
    getSupplierLastPurchase(supplierId) {
      return this.summaryData.lastPurchase[supplierId];
    },
    getSupplierUnreviewedCount(supplierId) {
      const itemsCount = this.getSupplierItemsCount(supplierId);
      if (itemsCount) return itemsCount.unknownItemsCount - itemsCount.pendingCount || '';
    },
    getPendingReasonCount(supplierId) {
      const reasons = this.getSupplierItemsCount(supplierId).reasons;
      const details = [];
      let length = reasons.filter((r) => r === 'notPurchasedRecently').length;
      if (length) details.push(`not purchased recently: ${length}`);
      length = reasons.filter((r) => r === 'uomUknown').length;
      if (length) details.push(`purchase unit of measure unknown: ${length}`);
      length = reasons.filter((r) => r === 'goodUknown').length;
      if (length) details.push(`good unknown: ${length}`);
      return details.join(', ');
    },
    routeToItems(supplierId) {
      this.$router.push({
        name: 'clarity.supplierItems',
        params: { supplierId },
      });
    },
    editSupplier(supplierId) {
      this.editedSupplierId = supplierId;
      this.dialogVisible = true;
    },
    closeSupplierForm() {
      this.editedSupplierId = null;
      this.dialogVisible = false;
    },
  },
  meteor: {
    $subscribe: {
      'suppliers.all': [],
    },
    allSuppliers() {
      return Suppliers.find({});
    },
  },
};
</script>
