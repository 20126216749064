<template>
  <div class="container">
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">
          <template v-if="supplier">{{ supplier.name }}</template>
          <div v-else class="bg-light" style="height: 13.75px"></div>
        </h6>
        <h2 class="dashhead-title">Items</h2>
      </div>
    </div>

    <el-tabs type="card">
      <el-tab-pane label="Items">
        <div class="row mb-4">
          <div class="col">
            <el-input
              v-model="searchTerm"
              prefix-icon="el-icon-search"
              placeholder="Search (by code or name)"
              clearable
            ></el-input>
          </div>
          <div class="col">
            <el-button-group class="float-right">
              <el-button @click="itemFormVisible = true">Add</el-button>
              <el-button icon="el-icon-upload2" @click="importDialogVisible = true"></el-button>
            </el-button-group>
          </div>
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Purchase Unit</th>
              <th>Type</th>
              <th>Groups</th>
              <th style="width: 4rem"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!$subReady['items.bySupplier']">
              <td v-loading="true" colspan="5" style="height: 6rem"></td>
            </tr>
            <template v-else-if="pageItems">
              <tr v-for="item in pageItems" :key="item._id">
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td></td>
                <td></td>
                <td>
                  <template v-if="$subReady['itemGroups.bySupplier']">
                    {{ getItemGroups(item._id) }}
                  </template>
                </td>
                <td>
                  <el-button type="text" size="mini" icon="el-icon-edit" class="p-0" @click="editItem(item)" />
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="5">No Items</td>
            </tr>
          </tbody>
        </table>

        <el-pagination
          small
          background
          :total="totalItems"
          :page-size="rowsPerPage"
          :current-page.sync="page"
          layout="prev, slot, total, next"
          class="text-center"
        >
          <span class="el-pagination__total">Showing {{ firstRowIndex }} - {{ lastRowIndex }}, </span>
        </el-pagination>
      </el-tab-pane>

      <el-tab-pane label="Groups">
        <el-button class="float-right" @click="itemGroupFormVisible = true">Add</el-button>
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Number of Items</th>
              <th style="width: 4rem"></th>
              <th style="width: 4rem"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!$subReady['itemGroups.bySupplier']">
              <td v-loading="true" colspan="4" style="height: 6rem"></td>
            </tr>
            <template v-else-if="itemGroups.length">
              <tr v-for="group in itemGroups" :key="group._id">
                <td>{{ group.name }}</td>
                <td>{{ group.itemIds.length }}</td>
                <td>
                  <el-button
                    type="text"
                    size="mini"
                    icon="el-icon-edit"
                    class="p-0"
                    @click="editItemGroup(group._id)"
                  />
                </td>
                <td>
                  <el-button
                    type="text"
                    size="mini"
                    icon="el-icon-delete"
                    class="text-danger p-0"
                    @click="removeItemGroup(group._id)"
                  />
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="4">No Item Groups</td>
            </tr>
          </tbody>
        </table>
      </el-tab-pane>
    </el-tabs>

    <item-form
      :visible.sync="itemFormVisible"
      :supplier-id="supplierId"
      :item-id="editedItemId"
      :defaults="editedItem"
      @close="onFormClose"
    />

    <el-dialog :visible.sync="importDialogVisible" title="Import Items" @close="resetImportForm">
      <el-form ref="importForm" :model="importModel" :rules="importRules" label-position="top">
        <div class="row">
          <div class="col-4">
            <el-form-item label="Name Column" prop="name">
              <el-input v-model="importModel.name" />
            </el-form-item>
          </div>
          <div class="col-4">
            <el-form-item label="SKU Column" prop="sku">
              <el-input v-model="importModel.sku" />
            </el-form-item>
          </div>
          <div class="col-4">
            <el-form-item label="Manufacturer Code Column" prop="manufacturerCode">
              <el-input v-model="importModel.manufacturerCode" />
            </el-form-item>
          </div>
          <div class="col-4">
            <el-form-item label="Item Rows" prop="itemRows">
              <el-input v-model="importModel.itemRows" />
            </el-form-item>
          </div>
          <div class="col-4">
            <el-form-item label="Excel File" prop="file">
              <el-upload
                ref="upload"
                action=""
                :auto-upload="false"
                :limit="1"
                :on-change="fileChange"
                :on-remove="fileRemove"
              >
                <el-button slot="trigger">select</el-button>
              </el-upload>
            </el-form-item>
          </div>
          <div class="col-4">
            <el-button type="primary" @click="retrieveItems">Retrieve Items</el-button>
          </div>
        </div>
      </el-form>
      <template v-if="importModel.items">
        <h3>Items</h3>
        <table class="table">
          <thead>
            <tr>
              <th>code</th>
              <th>name</th>
              <th>manufactor code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="importModel.items.length">
              <tr v-for="(item, index) in importModel.items" :key="index">
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.manufacturerCode }}</td>
                <td><i v-if="item.status" :class="`el-icon-${item.status}`"></i></td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="4">No items found</td>
            </tr>
          </tbody>
        </table>
        <el-button v-if="importModel.items.length" type="primary" @click="insertItems">Insert</el-button>
      </template>
    </el-dialog>

    <item-group-form
      :visible.sync="itemGroupFormVisible"
      :supplier-id="supplierId"
      :item-group-id="editedItemGroupId"
      :defaults="editedItemGroup"
      @close="editedItemGroupId = null"
    />
  </div>
</template>

<script type="text/javascript">
import XLSX from 'xlsx';
import escapeStringRegexp from 'escape-string-regexp';
import { Suppliers, Items, ItemGroups } from '@/imports/api/collections';
import { insertItem, removeItemGroup } from '@/modules/suppliers/compositions/suppliers-oprations';
import ItemForm from './components/ItemForm';
import ItemGroupForm from './components/ItemGroupForm';

export default {
  components: { ItemForm, ItemGroupForm },
  data() {
    return {
      editedItemId: null,
      editedItem: null,
      itemFormVisible: false,
      editedItemGroupId: null,
      itemGroupFormVisible: false,
      page: 1,
      rowsPerPage: 10,
      searchTerm: '',
      importDialogVisible: false,
      importModel: {
        name: '',
        sku: '',
        manufacturerCode: '',
        itemRows: '',
        file: null,
        items: null,
      },
      importRules: {
        name: { required: true, message: 'Please enter name column' },
        itemRows: { required: true, message: 'Please enter item rows' },
        file: { required: true, message: 'Please select excel file' },
      },
    };
  },
  computed: {
    firstRowIndex() {
      return this.totalItems && (this.page - 1) * this.rowsPerPage + 1;
    },
    lastRowIndex() {
      return Math.min(this.page * this.rowsPerPage, this.totalItems);
    },
    searchRegexp() {
      const escaped = escapeStringRegexp(this.searchTerm);
      const nonContinuity = escaped.split('').join('.*');
      return new RegExp(nonContinuity);
    },
    editedItemGroup() {
      const group = this.itemGroups.find((g) => g._id === this.editedItemGroupId);
      if (!group) return;
      const groupCopy = { ...group };
      delete groupCopy._id;
      return groupCopy;
    },
    supplierId() {
      return this.$route.params.supplierId;
    },
  },
  methods: {
    editItem(item) {
      const { name, code, manufacturerCode } = item;
      this.editedItem = { name, code, manufacturerCode };
      this.editedItemId = item._id;
      this.itemFormVisible = true;
    },
    getItemGroups(itemId) {
      return this.itemGroups
        .filter((g) => g.itemIds.includes(itemId))
        .map((g) => g.name)
        .join(', ');
    },
    onFormClose() {
      this.editedItemId = null;
      this.editedItem = null;
    },
    editItemGroup(groupId) {
      this.editedItemGroupId = groupId;
      this.itemGroupFormVisible = true;
    },
    removeItemGroup(groupId) {
      this.$confirm('Are you sure?').then(() => {
        removeItemGroup({ itemGroupId: groupId })
          .then(() => {
            this.$message.success('Ite group removed');
          })
          .catch((err) => {
            this.$message.error(`${err.error} - ${err.reason}`);
          });
      });
    },
    resetImportForm() {
      this.$refs.importForm.resetFields();
      this.$refs.upload.clearFiles();
      this.importModel.file = null;
      this.importModel.items = null;
    },
    fileChange(file) {
      this.importModel.file = file;
    },
    fileRemove() {
      this.importModel.file = null;
    },
    retrieveItems() {
      this.$refs.importForm.validate((valid) => {
        if (valid) {
          const rows = this.importModel.itemRows.split(',').reduce((total, range) => {
            const rows = [];
            if (range.includes('-')) {
              const arr = range.split('-').map((n) => Number(n));
              for (let i = arr[0]; i <= arr[1]; i++) rows.push(i);
            } else {
              rows.push(Number(range));
            }
            return total.concat(rows);
          }, []);

          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            this.importModel.items = [];
            rows.forEach((row) => {
              const item = {};
              item.name = worksheet[`${this.importModel.name}${row}`] && worksheet[`${this.importModel.name}${row}`].v;
              if (this.importModel.sku)
                item.code = worksheet[`${this.importModel.sku}${row}`] && worksheet[`${this.importModel.sku}${row}`].v;
              if (this.importModel.manufacturerCode)
                item.manufacturerCode =
                  worksheet[`${this.importModel.manufacturerCode}${row}`] &&
                  worksheet[`${this.importModel.manufacturerCode}${row}`].v;
              item.status = '';
              this.importModel.items.push(item);
            });
          };

          reader.readAsBinaryString(this.importModel.file.raw);
        }
      });
    },
    insertItems() {
      const itemsInserts = this.importModel.items.map((item) => {
        item.supplierId = this.supplierId;
        const addItem = Object.assign({}, item);
        Items.simpleSchema().clean(addItem, { mutate: true });
        return insertItem(addItem)
          .then(() => (item.status = 'check'))
          .catch(() => (item.status = 'close'))
          .then(() => item);
      });
      Promise.all(itemsInserts).then(() => {
        this.$message.success('Finished');
      });
    },
  },
  meteor: {
    $subscribe: {
      'suppliers.single'() {
        return [this.supplierId];
      },
      'items.bySupplier'() {
        return [this.supplierId];
      },
      'itemGroups.bySupplier'() {
        return [this.supplierId];
      },
    },
    items() {
      return Items.find({ supplierId: this.supplierId }, { sort: { name: 1 } });
    },
    pageItems() {
      const query = {
        supplierId: this.supplierId,
        $or: [{ name: this.searchRegexp }, { code: this.searchRegexp }],
      };
      return Items.find(query, {
        sort: { name: 1 },
        limit: this.rowsPerPage,
        skip: (this.page - 1) * this.rowsPerPage,
      });
    },
    totalItems() {
      return Items.find({ name: this.searchRegexp }).count();
    },
    itemGroups() {
      return ItemGroups.find({ supplierId: this.supplierId }, { sort: { name: 1 } });
    },
    supplier() {
      return Suppliers.findOne(this.supplierId);
    },
  },
  i18n: {
    messages: {
      en: {
        reasons: {
          notPurchasedRecently: 'not purchased recently',
          uomUknown: 'purchase unit of measure unknown',
          goodUknown: 'good unknown',
        },
      },
    },
  },
};
</script>
